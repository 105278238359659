<template lang="pug">
transition(:name='transition')
  div(v-if='visible' ref='element')
    slot
</template>
<script lang="ts">
import { type RegisterDrilldownItemFn } from './Drilldown.vue'
import { useElementSize } from '@vueuse/core'
import { computed, type ComputedRef, defineComponent, inject, ref, type Ref, watch } from 'vue'

export const DrilldownItem = defineComponent({
  props: { id: [String, Number] },
  setup(props) {
    const element = ref<HTMLElement | null>(null)
    const { height } = useElementSize(element as any)
    const index = inject<RegisterDrilldownItemFn>('registerDrilldownItem')?.(props.id!)
    const currentIndex = inject<ComputedRef<number>>('currentIndex')!
    const transition = inject<Ref<string>>('transition')!
    const setHeight = inject<any>('setHeight')
    const visible = computed(() => currentIndex?.value === index)

    watch(
      () => [visible.value, height.value],
      ([v, h]) => v && h && setHeight(h),
    )

    return {
      height,
      element,
      transition,
      visible,
    }
  },
})

export default DrilldownItem
</script>
