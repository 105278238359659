<template lang="pug">
div(class='drilldown')
  div(ref='element' class='drilldown__stage')
    slot
  slot(name='after' :children='children' :currentIndex='currentIndex' :prev='prev' :next='next')
</template>
<script lang="ts">
import { useDebounceFn, useVModel } from '@vueuse/core'
import { computed, defineComponent, provide, ref, watch } from 'vue'

export type RegisterDrilldownItemFn = (id: string | number) => number

export interface IDrilldownState {
  transition: 'prev' | 'next'
  activeItem: string | number | undefined
}
const Drilldown = defineComponent({
  props: {
    modelValue: { type: [String, Number], required: true },
  },
  setup(props, { emit }) {
    const element = ref<HTMLElement | null>(null)
    const children = ref<(number | string)[]>([])
    const childrenAsMap = computed(() => Object.fromEntries(children.value.map((id, index) => [id, index])))
    const transition = ref('prev')
    const activeItem = useVModel(props, 'modelValue', emit)
    const currentIndex = computed(() => childrenAsMap.value[props.modelValue] ?? undefined)

    provide<RegisterDrilldownItemFn>(
      'registerDrilldownItem',
      (id: string | number) => (children.value.push(id), childrenAsMap.value[id]),
    )
    provide('transition', transition)
    provide('currentIndex', currentIndex)
    let timeout: any = null
    const setHeightDebounced = useDebounceFn(
      (h: number) => (
        setContainerStyle('height', `${element.value?.getBoundingClientRect().height || 0}px`),
        setTimeout(() => {
          setContainerStyle('height', `${h}px`)
          timeout = setTimeout(() => {
            setContainerStyle('height', 'auto')
            setContainerStyle('overflow', 'unset')
          }, 210)
        })
      ),
      50,
    )
    provide('setHeight', (h: number) => {
      setContainerStyle('overflow', 'hidden')
      setHeightDebounced(h)
    })
    const setContainerStyle = (property: string, value: string) =>
      element.value?.style && (element.value!.style[property as any] = value)

    watch(
      () => activeItem.value,
      (current: string | number, prev: string | number | undefined) =>
        current !== prev &&
        (clearTimeout(timeout),
        setContainerStyle('height', `${element.value!.getBoundingClientRect().height}px`),
        (transition.value =
          prev === undefined || current === undefined || childrenAsMap.value[current] < childrenAsMap.value[prev]
            ? 'prev'
            : 'next')),
    )

    return {
      element,
      children,
      currentIndex,
      transition,
      activeItem,
      prev: () => currentIndex.value > 0 && (activeItem.value = children.value[currentIndex.value - 1]),
      next: () =>
        currentIndex.value < children.value.length - 1 && (activeItem.value = children.value[currentIndex.value + 1]),
    }
  },
})

export default Drilldown
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.drilldown
  &__stage
    margin-bottom: 0px
    width: 100%
    position: relative
    transition: height .2s ease-in-out
    height: auto
    > *
      width: 100%

.next-enter-from
  opacity: 0.1
  position: absolute
  transform: translate3d(100%, 0, 0)
.next-enter-active,
.next-leave-active
  position: absolute
  transition: 0.2s ease-in-out
.next-leave-to
  opacity: 0.1
  position: absolute
  transform: translate3d(-100%, 0, 0)

.prev-enter-from
  opacity: 0.1
  position: absolute
  transform: translate3d(-100%, 0, 0)
.prev-enter-active,
.prev-leave-active
  position: absolute
  transition: 0.2s ease-in-out
.prev-leave-to
  opacity: 0.1
  position: absolute
  transform: translate3d(100%, 0, 0)
</style>
